import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImage from './images/summary-background.jpg';

import {
  gamesQuery,
  scoresStatsQuery,
  scenarioLevelsQuery
} from './api';

import {
  popupMessagesAccuracy,
  popupMessagesAccuracyItems,
  popupMessagesAccuracyGeneralTexts
} from './data';

import Analysis from '../UI/game/analysis';
import PopupMessages from '../UI/introGameLayout/popupMessages';

import './styles.css';

export default function GameAccuracy() {
  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const navigate = useNavigate();
  
  const { 
    getUser,
    getGuidedPlay
  } = useAuth();

  const user = getUser();
  const username = user?.alias_name 
    ? user?.alias_name 
    : user?.first_name 
      ? user?.first_name
      : user?.username
        ? user?.username 
        : '';
  
  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Account Classification'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();

  const [data, setData] = useState([]);
  const [rank, setRank] = useState('');
  const [accuracy, setAccuracy] = useState('');
  const [avarageTime, setAvarageTime] = useState('');
  const [levelsCount, setLevelsCount] = useState(0);

  const { data: scenarioLevels } = useQuery(scenarioLevelsQuery({scenarioId}));
  const { data: scoresStats } = useQuery(scoresStatsQuery({ gameId: currentGameId }));

  useEffect(() => {
    if(scoresStats && scenarioLevels?.length) {
      setLevelsCount(scenarioLevels.length);

      const levelScoresStats = scoresStats.find(scoresStat => scoresStat.level === levelNumber);

      setRank(levelScoresStats?.rank);
      setAccuracy(`${parseFloat((levelScoresStats?.user_accuracy * 100).toFixed(2)).toString()}%`);
      setAvarageTime(parseFloat(levelScoresStats?.user_average_time?.toFixed(2)).toString());

      const firstBarsData = [
        { title: username, score: levelScoresStats?.user_accuracy * 100, color: '#F9C151' },
        { title: 'Mean', score: levelScoresStats?.average_accuracy * 100 },
        { title: 'Median', score: levelScoresStats?.median_accuracy * 100 },
      ];
      const secondBarsData = [
        { title: username, score: levelScoresStats?.user_average_time, color: '#F9C151' },
        { title: 'Mastery', score: 2, color: '#5C91F5' },
        { title: 'Shortest Time', score: levelScoresStats?.shortest_average_time },
        { title: 'Median', score: levelScoresStats?.median_average_time },
        { title: 'Longest Time', score: levelScoresStats?.longest_average_time },
      ];
      const data = [
        {
          title: 'Accuracy',
          bars: firstBarsData,
          barsLabel: '%'
        },
        {
          title: 'Average Time',
          subtitle: 'In Seconds',
          bars: secondBarsData
        }
      ]
      setData(data);
    }
  }, [username, scoresStats, levelNumber, scenarioLevels]);

  let popupMessages = rank ? popupMessagesAccuracy[rank] : [];

  const items = popupMessagesAccuracyItems[levelNumber - 1];
  const generalTexts = popupMessagesAccuracyGeneralTexts[levelNumber - 1];

  if(popupMessages) {
    popupMessages = popupMessages.map(message => ({
      ...message,
      text: message.text.replace('{items}', items)
        .replace('{accuracy}', accuracy)
        .replace('{avarageTime}', avarageTime)
        .replace('{name}', username)
        .replace('{generalTexts}', generalTexts)
    }));
  }

  const handlePopupMessagesNextButtonClick = () => {
    let redirectUrl;

    if(rank === 'Master') {
      if(levelNumber < levelsCount) {
        if(shouldGuidedPagesBeDisplayed) {
          redirectUrl = `/classification/level/${levelNumber + 1}/intro`;
        } else {
          redirectUrl = `/classification/level/${levelNumber + 1}/`;
        }
      } else {
        redirectUrl = `/classification/feedback`;
      }
    } else {
      redirectUrl = `/classification/level/${levelNumber}/most-challenging`;
    }

    navigate(redirectUrl);
  }

  return(
    <Layout
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={false}
      isHeaderVisible={true}
      isFooterVisible={false}
    >
      {
        popupMessages && popupMessages.length &&
          <PopupMessages
            messages={popupMessages}
            buttonText="Next"
            onButtonClick={handlePopupMessagesNextButtonClick}
            messageSize="small"
          />
      }

      <Analysis
        heading={`Level ${levelNumber} Analysis`}
        data={data}
      />
    </Layout>
  );
}
