export const blockItems = [
  [
    {
      text: 'Assets',
      answer: 'Balance Sheet',
      description: <p>An <b>Asset</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Expenses',
      answer: 'Income Statement',
      description: <p>An <b>Expenses</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Equity',
      answer: 'Balance Sheet',
      description: <p>An <b>Equity</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Revenues',
      answer: 'Income Statement',
      description: <p>A <b>Revenues</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Liabilities',
      answer: 'Balance Sheet',
      description: <p>A <b>Liabilities</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }
  ],
  [
    {
      text: 'Current Assets',
      answer: 'Assets',
      description: <p>A <b>Current Assets</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Long Term Assets',
      answer: 'Assets',
      description: <p>A <b>Long Term Assets</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Current Liabilities',
      answer: 'Liabilities',
      description: <p>A <b>Current Liabilities</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Long Term Liabilities',
      answer: 'Liabilities',
      description: <p>A <b>Long Term Liabilities</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Contributed Capital',
      answer: 'Equity',
      description: <p>A <b>Contributed Capital</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Earned Capital',
      answer: 'Equity',
      description: <p>An <b>Earned Capital</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Revenues',
      answer: 'Revenues',
      description: <p>A <b>Revenues</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Direct Expenses',
      answer: 'Expenses',
      description: <p>A <b>Direct Expenses</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Indirect Expenses',
      answer: 'Expenses',
      description: <p>An <b>Indirect Expenses</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }
  ],
  [
    {
      text: 'Cash',
      answer: 'Current Assets',
      description: <p><b>Cash</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Accounts Receivable',
      answer: 'Current Assets',
      description: <p><b>Accounts Receivable</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Inventory',
      answer: 'Current Assets',
      description: <p><b>Inventory</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Property, Plant, and Equipment',
      answer: 'Long-term Assets',
      description: <p><b>Property, Plant, and Equipment</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Accounts Payable',
      answer: 'Current Liabilities',
      description: <p><b>Accounts Payable</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Wages & Salaries Payable',
      answer: 'Current Liabilities',
      description: <p><b>Wages & Salaries Payable</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Bonds Payable',
      answer: 'Long-term Liabilities',
      description: <p><b>Bonds Payable</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Stock Issuance',
      answer: 'Contributed Capital',
      description: <p><b>Stock Issuance</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Retained Earnings',
      answer: 'Earned Capital',
      description: <p><b>Retained Earnings</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Net Revenues',
      answer: 'Revenues',
      description: <p><b>Net Revenues</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Cost of Goods Sold',
      answer: 'Direct Expenses',
      description: <p><b>Cost of Goods Sold</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Research & development (R&D)',
      answer: 'Indirect Expenses',
      description: <p><b>Research & development (R&D)</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Marketing',
      answer: 'Indirect Expenses',
      description: <p><b>Marketing</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'General & Administrative (G&A)',
      answer: 'Indirect Expenses',
      description: <p><b>General & Administrative (G&A)</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Interest Expenses',
      answer: 'Indirect Expenses',
      description: <p><b>Interest Expenses</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }
  ]
];


export const leftColumnAnswers = [
  [
    {
      title: 'Balance Sheet'
    }
  ],
  [
    {
      title: 'Assets'
    },
    {
      title: 'Liabilities'
    },
    {
      title: 'Equity'
    }
  ],
  [
    {
      title: 'Current Assets',
      parentTitle: 'Assets'
    },
    {
      title: 'Long-term Assets',
      parentTitle: 'Assets',
      groupTitle: true
    },
    {
      title: 'Current Liabilities',
      parentTitle: 'Liabilities'
    },
    {
      title: 'Long-term Liabilities',
      parentTitle: 'Liabilities',
      groupTitle: true
    },
    {
      title: 'Contributed Capital',
      parentTitle: 'Equity'
    },
    {
      title: 'Earned Capital',
      parentTitle: 'Equity',
      groupTitle: true
    },
  ]
];

export const rightColumnAnswers = [
  [
    {
      title: 'Income Statement'
    }
  ],
  [
    {
      title: 'Revenues'
    },
    {
      title: 'Expenses'
    }
  ],
  [
    {
      title: 'Revenues',
      groupTitle: true
    },
    {
      title: 'Direct Expenses',
      parentTitle: 'Expenses'
    },
    {
      title: 'Indirect Expenses',
      parentTitle: 'Expenses',
      groupTitle: true
    }
  ]
];


export const buttonTitles = ['The Elements of the Financial Statements', 'Financial Statements Sub-Categories', 'Financial Statements Accounts'];
export const modalDescriptions = [
  'Sort the Elements to the correct Financial Statements',
  'Sort each sub-categories under the correct element of the financial statements',
  'Sort each account under the correct sub-categories of the financial statements',
];

export const popupMessagesGameLevel1 = [
  {
    name: 'Bailey',
    text: 'Today, we\'re doing a training exercise called <b>The Account Classification game</b>.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'This exercise is designed to deepen your understanding of the <a href="https://www.accountingtools.com/articles/what-are-the-elements-of-financial-statements.html" target="_blank" rel="noreferrer">5 elements of accounting</a> and their influence in classifying all financial statements accounts.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'In the exercise, accounts are falling and your task is to sort these accounts into their respective statements. <br>You’ll earn points and badges based on the speed and accuracy of your classifications. ',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'The exercise begins with simply sorting the 5 elements and gradually increases in difficulty with sorting accruals and deferrals accounts in level 3. <br><br>That said, let\'s begin with Level I.',
    alignment: 'right'
  },
];

export const popupMessagesGameLevel2 = [
  {
    name: 'Bailey',
    text: 'Congratulations on finishing Level I! You\'ve established the foundational building blocks of financial statements: <a href="https://www.accountingtools.com/articles/what-are-the-elements-of-financial-statements.html" target="_blank" rel="noreferrer">the 5 elements</a></a>.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'In this next level, we’re going to present several commonly used financial statement accounts.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Your task is to classify these accounts according to their respective element: Assets, liabilities, equity, revenues, or expenses.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip.</b> To identify if something is an asset, see if it meets these three conditions: <ul><li>The business owns or controls it.</li> <li>It provides future economic benefits.</li> <li>It can be sold for a material cash amount.</li></ul> All the best!',
    alignment: 'right'
  },
];

export const popupMessagesGameLevel3 = [
  {
    name: 'Bailey',
    text: 'Congrats on completing Level 2! You’ve now sorted a few commonly used accounts to their correct elements of the financial statement.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'In this next level, we’ll present you with accrual and deferral accounts, which are known to be tricky to classify due to their names.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Your task is to classify these accounts according to their respective element: Assets, liabilities, equity, revenues, or expenses.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> If the account involves the business <b>"paying cash before receiving a service"</b>, it’s usually an asset. If the account involves the business <b>"receiving cash before providing a service"</b>, it’s typically a liability.<br><br> Sending you positive vibes!',
    alignment: 'left'
  },
];


export const popupMessagesGameLevel4 = [
  {
    name: 'Bailey',
    text: 'Congrats on making it to the bonus level! Only the top performers make it this far.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'You’re now ready to sort the accounts of a real company.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Your task is to 1) select a company and 2) sort their scrambled accounts to the correct financial statement element.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> If you\'re unsure about an account, hover over it and use the provided definition for guidance.<br><br> Best of luck!',
    alignment: 'right'
  },
];

export const popupMessagesAccuracy = {
  Master: [
    {
      name: 'Benny',
      text: 'Congratulations {name}! You sorted the {items} with {accuracy} accuracy in an average of 2 seconds or less.'
    },
    {
      name: 'Benny',
      text: 'You’re performing at a mastery level. Great job!'
    },
    {
      name: 'Benny',
      text: '{generalTexts}'
    },
  ],
  Warrior: [
    {
      name: 'Benny',
      text: 'Impressive {name}! You sorted the {items} with {accuracy} accuracy in an average of {avarageTime} seconds.'
    },
    {
      name: 'Benny',
      text: 'To demonstrate mastery, you must sort all items with 100% accuracy in 2 seconds or less.'
    },
  ],
  Newbie: [
    {
      name: 'Benny',
      text: 'Not bad {name}! You sorted the {items} with {accuracy} accuracy in an average of {avarageTime} seconds.'
    },
    {
      name: 'Benny',
      text: 'To demonstrate mastery, you must sort all items with 100% accuracy in 2 seconds or less.',
    },
  ],
  Noob: [
    {
      name: 'Benny',
      text: 'You sorted the {items} with {accuracy} accuracy in an average of {avarageTime} seconds.'
    },
    {
      name: 'Benny',
      text: 'To demonstrate mastery, you must sort all items with 100% accuracy in 2 seconds or less.'
    },
  ]
};

export const popupMessagesAccuracyItems = [
  'Elements',
  'accounts',
  'accrual &amp; deferral accounts',
  'various accounts'
];

export const popupMessagesAccuracyGeneralTexts = [
  'Understanding the 5 elements—assets, liabilities, equity, revenue, and expenses—are crucial because they form the foundation of financial statements. That is to say, all financial statement accounts fall under one of the 5 elements, and knowing these elements makes it easier for you to understand how all other accounts behave.',
  'These are common accounts that you’ll find on a company’s financial statement. Understanding which elements these accounts belong to helps in comprehending how they impact financial statements.',
  'Accrual and deferral accounts can sometimes be confusing due to their names. For example, deferral accounts such as prepaid expenses (assets) are payments made in advance, and unearned revenues (liabilities) are cash received for future services.',
  'A key takeaway from this exercise is becoming familiar with accounts that are commonly used in financial statements and accurately categorizing these accounts into their respective financial statement elements—assets, liabilities, equity, revenue, and expenses. Mastering account classification helps build a solid foundation for more advanced accounting concepts and practices.'
];

export const popupMessagesMostChallenging = [
  {
    name: 'Benny',
    text: 'These were the most challenging {items} for you based on your accuracy and speed.'
  },
  {
    name: 'Benny',
    text: '{generalTexts}'
  },
];

export const tutorialBlockItems = [{
  name: 'Assets',
  answers: ['Balance Sheet', 'Debit'],
  description: <span>An <b>Asset</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</span>,
  secondsFalling: -1,
  accuracyCoefficient: 1
}];

export const tutorialOptions = [
  {
    "id": 1,
    "order": 1,
    "name": "Balance Sheet",
    "description": "A balance sheet is a financial statement that provides a snapshot of a company's financial position at a specific point in time. It is one of the key financial statements used by businesses, investors, and analysts to assess the financial health and performance of an organization.",
    "children": [],
    "itemId": 1
  },
  {
    "id": 2,
    "order": 2,
    "name": "Income Statement",
    "description": "An income statement, also known as a profit and loss statement (P&L) is a financial statement that summarizes a company's revenues and expenses over a specific period of time.",
    "children": [],
    "itemId": 2
  }
];