import './styles.css';

export default function Hint({
  content,
  buttonText,
  onButtonClick,
  side = ''
}) {
  return (
    <div className={`hint-container ${side}`}>
      <div className="hint">
        {content}
        {
          buttonText && onButtonClick && 
            <button onClick={onButtonClick}>{buttonText}</button>
        }
      </div>
    </div>
  );
}
