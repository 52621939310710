import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';
import { useSoundStatus } from '../../context/SoundStatusContext';

import { calculateTimeInSeconds } from '../../utils';

import Intro from '../UI/game/intro';
import Header from '../UI/game/header';
import Modal from '../UI/modal';
import Column from '../UI/game/column';
import FooterMessage from '../UI/game/footer-message';

import { 
  gamesQuery,
  scenarioLevelsQuery,
  itemOptionsQuery,
  itemQuestionsQuery,
  itemsQuery,
  makeDecision,
  getDecisions,
  getGamesSessions
} from './api';

import { shuffleArray } from '../../utils';

import './styles.css';

import CountdownSound from './sounds/countdown.mp3';
import GameProcessSound from './sounds/game-process.mp3';
import ClickSound from './sounds/click.mp3';
import AnswerCorrectSound from './sounds/answer-correct.mp3';
import AnswerWrongSound from './sounds/answer-wrong.mp3';

import Background from './images/sorting-backgrounds/sorting-background.jpg';

const getDeepestLevelItems = (options) => {
  const deepestItems = [];

  function traverse(node) {
    if (!node.children || node.children.length === 0) {
      deepestItems.push({id: node.id, itemId: node.itemId});
    } else {
      node.children.forEach(child => traverse(child));
    }
  }

  options.forEach(item => traverse(item));

  return deepestItems;
}

const findDeepestParentId = (itemParents, options, currentDepth = 0, maxDepthInfo = { depth: 0, id: null }) => {
  options.forEach(option => {
    if (itemParents.includes(option.itemId)) {
      if (currentDepth >= maxDepthInfo.depth) { // Use >= to include top-level elements
        maxDepthInfo.depth = currentDepth;
        maxDepthInfo.id = option.id;
      }
    }
    if (option.children && option.children.length > 0) {
      findDeepestParentId(itemParents, option.children, currentDepth + 1, maxDepthInfo);
    }
  });
  return maxDepthInfo.id;
};


export default function NormalBalanceSorting({levelNumber}) {
  const countdownEffect = useRef(new Audio(CountdownSound));
  const gameProcessEffect = useRef(new Audio(GameProcessSound));
  const clickEffect = useRef(new Audio(ClickSound));
  const answerCorrectEffect = useRef(new Audio(AnswerCorrectSound));
  const answerWrongEffect = useRef(new Audio(AnswerWrongSound));

  const navigate = useNavigate();
  const location = useLocation();

  /* Prevent sound from playing on other pages */
  useEffect(() => {
    const currentGameProcessEffect = gameProcessEffect.current;
  
    return () => {
      if (currentGameProcessEffect) {
        currentGameProcessEffect.pause();
      }
    };
  }, [location]);
  
  const { getUser } = useAuth();
  const user = getUser();
  
  const [currentGameId, setCurrentGameId] = useState(0);
  const [scenarioId, setScenarioId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Normal Balance'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
      }
    }
  }, [games]);

  const { soundStatus } = useSoundStatus();

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessions'],
    queryFn: getGamesSessions,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if(currentGameId) {
      if (gamesSessions && !gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
        navigate('/normal/tutorial');
      }
    }
  }, [gamesSessions, navigate, user?.id, currentGameId]);

  const [scenarioLevel, setScenarioLevel] = useState({
    description: '',
    fallingSpeed: 1,
    enabled: false
  });

  const [isPlaying, setIsPlaying] = useState(false);

  const [hoveredItem, setHoveredItem] = useState(0);
  const [placedItems, setPlacedItems] = useState({});

  const scenarioLevelsResponse = useQuery(scenarioLevelsQuery({scenarioId})) || {};

  const itemOptionsResponse = useQuery(itemOptionsQuery()) || {};
  
  const itemQuestionsResponse = useQuery(itemQuestionsQuery({scenarioLevelId: scenarioLevel.id})) || {};

  const itemsResponse = useQuery(itemsQuery()) || {};

  // const decisionsResponse = useQuery(decisionsQuery(levelNumber, user?.id)) || {};
  const { data: decisionsResponse, isSuccess: isDecisionsSuccess } = useQuery({
    queryKey: ['decisionsResponse', { levelNumber, userId: user?.id, gameId: currentGameId }],
    queryFn: () => getDecisions({ levelNumber, userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && levelNumber !== undefined && !!currentGameId,
    refetchOnWindowFocus: false,
  });
  
  useEffect(() => {
    const scenarioLevels = scenarioLevelsResponse.data || [];
    if(scenarioLevels.length) {
      const scenarioLevel = scenarioLevels.find(scenarioLevel => scenarioLevel.level === levelNumber);
      setScenarioLevel({
        id: scenarioLevel.id,
        description: scenarioLevel.description,
        fallingSpeed: scenarioLevel.falling_speed * 1000,
        duration: calculateTimeInSeconds(scenarioLevel.duration)
      });
    }
  }, [levelNumber, scenarioLevelsResponse.data]);

  const [options, setOptions] = useState([]);

  const [totalScore, setTotalScore] = useState(0);
  const [shouldScorePlaySound, setShouldScorePlaySound] = useState(false);

  const [itemsToBeSorted, setItemsToBeSorted] = useState([]);

  useEffect(() => {
    const itemOptions = itemOptionsResponse.data || [];
    const itemQuestions = itemQuestionsResponse.data || [];
    const items = itemsResponse.data || [];
    
    if(
      itemOptions.length &&
      itemQuestions.length &&
      items.length &&
      isDecisionsSuccess
    ) {
      const decisions = decisionsResponse || [];
      
      const scenarioLevelItemOptions = itemOptions.filter(itemOption => itemOption.scenario_level === scenarioLevel.id);

      const createItemObject = (item, children = []) => {
        const scenarioLevelItemOption = itemOptions.find(scenarioLevelItemOption => {
          if (item && 'id' in item) {
            return scenarioLevelItemOption.account_item === item.id;
          }
          return false;
        });
        
        const hierarchicalItem = {
          id: scenarioLevelItemOption && scenarioLevelItemOption.id,
          order: scenarioLevelItemOption && scenarioLevelItemOption.order,
          name: item && item.name,
          description: item && item.description,
          children: Array.isArray(children) ? children : [children],
          itemId: item && item.id
        };
      
        if (item && item.parents.length) {
          const parentItemIdWithOption = item.parents.find(parentId => {
            return scenarioLevelItemOptions.some(option => option.account_item === parentId);
          });
      
          if (parentItemIdWithOption) {
            const parentItem = items.find(possibleParentItem => possibleParentItem.id === parentItemIdWithOption);
            if (parentItem) {
              return createItemObject(parentItem, [hierarchicalItem]);
            }
          } else {
            return hierarchicalItem;
          }
        } else {
          return hierarchicalItem;
        }
      }

      const hierarchicalOptions = scenarioLevelItemOptions.map(scenarioLevelItemOption => {
        const relatedItem = items.find(item => item.id === scenarioLevelItemOption.account_item);
        return createItemObject(relatedItem);
      });
      
      function mergeAndConcatenateDuplicates(items) {
        const uniqueItemsMap = new Map();
      
        items.forEach(item => {
          if (!uniqueItemsMap.has(item.id)) {
            uniqueItemsMap.set(item.id, { ...item, children: item.children ? [...item.children] : [] });
          } else {
            const existingItem = uniqueItemsMap.get(item.id);
            if (item.children && item.children.length > 0) {
              existingItem.children = existingItem.children.concat(item.children);
            }
          }
        });
      
        uniqueItemsMap.forEach((value, key) => {
          if (value.children && value.children.length > 0) {
            value.children = mergeAndConcatenateDuplicates(value.children);
          }
        });
      
        return Array.from(uniqueItemsMap.values());
      }      

      function sortOptionsDataByOrder(data) {
        const sortedData = data.sort((a, b) => a.order - b.order);
      
        sortedData.forEach(item => {
          if (item.children && item.children.length > 0) {
            item.children = sortOptionsDataByOrder(item.children);
          }
        });
      
        return sortedData;
      }
      
      const optionsChildrenMerged = mergeAndConcatenateDuplicates(hierarchicalOptions);
      const sortedOptionsData = sortOptionsDataByOrder(optionsChildrenMerged);
      setOptions([...sortedOptionsData]);

      const questionsData = itemQuestions.map(itemQuestion => {
        const relatedItem = items.find(item => item.id === itemQuestion.account_item);
        return {
          id: itemQuestion.id,
          order: itemQuestion.order,
          name: relatedItem && relatedItem.name,
          description: relatedItem && relatedItem.description,
          parents: relatedItem && relatedItem.parents,
          isCorrectlyPlaced: false
        }
      });
      
      const correctDecisions = decisions.filter(decision => decision.is_correct === true);
      
      const correctQuestionIds = new Set(correctDecisions.flatMap(decision => decision.question));
      const [wrongQuestionsToBePlaced, correctQuestionsToBePlaced] = questionsData.reduce((result, question) => {
        if (correctQuestionIds.has(question.id)) {
          result[1].push(question);
        } else {
          result[0].push(question);
        }
        return result;
      }, [[], []]);

      let placedItemsByDropArea = {};
      correctQuestionsToBePlaced.forEach(item => {
        const deepestDropAreaId = findDeepestParentId(item.parents, sortedOptionsData);
        if (deepestDropAreaId) {
          placedItemsByDropArea[deepestDropAreaId] = placedItemsByDropArea[deepestDropAreaId] || [];
          placedItemsByDropArea[deepestDropAreaId].push({ ...item, isCorrectlyPlaced: true });
        }
      });

      const numWrongQuestions = wrongQuestionsToBePlaced.length;
      const numWrongQuestionsInCorrectArea = Math.ceil(0.2 * numWrongQuestions);
      const numWrongQuestionsInRandomAreas = numWrongQuestions - numWrongQuestionsInCorrectArea;
      
      let prePlacedItemsRandomIterator = 0;

      const deepestLevelItems = getDeepestLevelItems(sortedOptionsData);

      const shuffledWrongQuestionsToBePlaced = shuffleArray(wrongQuestionsToBePlaced);

      shuffledWrongQuestionsToBePlaced.forEach(item => {
        let dropAreaId = 0;
        if(prePlacedItemsRandomIterator <= numWrongQuestionsInRandomAreas) {
          const filteredDeepestLevels = deepestLevelItems.filter(deepestLevelItem => !item.parents.includes(deepestLevelItem.itemId));
          const randomIndex = Math.floor(Math.random() * filteredDeepestLevels.length);
          dropAreaId = filteredDeepestLevels[randomIndex]?.id;

          prePlacedItemsRandomIterator++;
        } else {
          dropAreaId = findDeepestParentId(item.parents, sortedOptionsData);
        }
              
        if (dropAreaId) {
          placedItemsByDropArea[dropAreaId] = placedItemsByDropArea[dropAreaId] || [];
          placedItemsByDropArea[dropAreaId].push({ ...item });
        }
      });

      // Update the state with the final placedItems
      setPlacedItems({...placedItemsByDropArea});

      const [totalElapsedTime, totalPointsScored] = correctDecisions.reduce(([totalTime, totalPoints], decision) => {
        let timeInSeconds = 0;
        if (decision.time_elapsed) {
          timeInSeconds = calculateTimeInSeconds(decision.time_elapsed);
        }

        totalTime += timeInSeconds;
        totalPoints += decision.score_points || 0;

        return [totalTime, totalPoints];
      }, [0, 0]);

      setTime(totalElapsedTime);
      setTotalScore(totalPointsScored);

      if(wrongQuestionsToBePlaced.length) {
        setItemsToBeSorted(wrongQuestionsToBePlaced);
        setModalToShow('countdown');
      } else {
        setModalToShow('well-done');
      }
    }
  }, [itemOptionsResponse.data, itemQuestionsResponse.data, itemsResponse.data, scenarioLevel.id, decisionsResponse, levelNumber, user?.id, isDecisionsSuccess]);

  const decisionMutation = useMutation({
    mutationFn: (data) => makeDecision(data)
  });

  const [isFooterMessageShown, setIsFooterMessageShown] = useState(false);
  const [footerMessageType, setFooterMessageType] = useState();
  const [footerMessageDescription, setFooterMessageDescription] = useState();

  const handleMouseEnter = (id, hoverContent) => {
    setHoveredItem(id);
    showFooterMessage('info', hoverContent);
  }

  const handleMouseLeave = () => {
    setHoveredItem(0);
    setIsFooterMessageShown(false);
  }

  const showFooterMessage = (type, description) => {
    setFooterMessageType(type);
    setFooterMessageDescription(description);
    setIsFooterMessageShown(true);
  }

  const [isModalOverlayVisible, setIsModalOverlayVisible] = useState(true);
  const [isModalSmall, setIsModalSmall] = useState(false);
  const [modalToShow, setModalToShow] = useState(null);

  const [modalContent, setModalContent] = useState(
    <div className="modal-center vertical">
      <div><span>Game start!</span></div>
      <div><span className='modal-counter'>3</span></div>
    </div>
  );

  const [time, setTime] = useState(0);
  const timeRef = useRef(time);
  useEffect(() => {
    timeRef.current = time;
  }, [time]);

  useEffect(() => {
    if (!isPlaying) return;
    const timerInterval = setInterval(() => {
      const newTime = timeRef.current + 1;
      setTime(newTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [isPlaying, scenarioLevel]);
  
  const [showIntro, setShowIntro] = useState(true);

  const updateModalTimeContent = () => {
    let countdown = 3;
  
    const countdownInterval = setInterval(() => {
      if (countdown === 0) {
        clearInterval(countdownInterval);
        setModalToShow(null);
        setIsPlaying(true);
  
        if (soundStatus === 'enabled') {
          gameProcessEffect.current.volume = 0.09;
          gameProcessEffect.current.loop = true;
          gameProcessEffect.current.play().catch(e => console.error('Error playing game process sound:', e));
        }
      } else {
        setModalContent(
          <div className="modal-center vertical">
            <div><span>Game start!</span></div>
            <div><span>{countdown}</span></div>
          </div>
        );
        countdown--;
  
        // Play countdown sound
        if (soundStatus === 'enabled') {
          countdownEffect.current.play().catch(e => console.error('Error playing countdown sound:', e));
        }
      }
    }, 1000);
  
    // Clean up interval on unmount or when countdown is complete
    return () => {
      clearInterval(countdownInterval);
    };
  };

  useEffect(() => {
    if (modalToShow === 'countdown' && !showIntro) {
      setIsModalOverlayVisible(true);
      setIsModalSmall(false);
      updateModalTimeContent();
    } else if (modalToShow === 'well-done') {
      setIsModalSmall(true);
      setIsModalOverlayVisible(false);
      setModalContent(
        <>
          <div className="modal-center vertical">
            <div><span>Well done!</span></div>
            <button onClick={() => navigate(`/normal/level/${levelNumber}/summary/`)} className="button-blue button-view-summary">View Summary</button>
          </div>
        </>
      );
    }
  }, [modalToShow, showIntro, levelNumber]); 

  const handleIntroClose = () => {
    setShowIntro(false);    
  }

  useEffect(() => {  
    if (isPlaying && soundStatus === 'enabled') {
      gameProcessEffect.current.volume = 0.09;
      gameProcessEffect.current.loop = true;
      gameProcessEffect.current.play().catch(e => console.log('Error playing game process sound:', e));
    } else {
      gameProcessEffect.current.pause();
    }
  
    return () => {
      gameProcessEffect.current.pause();
    };
  }, [isPlaying, soundStatus]);

  useEffect(() => {
    const playClickSound = () => {
      if (soundStatus === 'enabled') {
        clickEffect.current.play().catch(e => console.error('Error playing click sound:', e));
      }
    };

    document.addEventListener('click', playClickSound);

    return () => {
      document.removeEventListener('click', playClickSound);
    };
  }, [soundStatus]);

  const handleSortedItemClick = (item, container, callback) => {
    const onSuccess = (result) => {
      callback(null, result);

      const footerMessagePrefix = 'The Normal Balance for ';

      const footerMessageMiddle = result.is_correct
        ? ' is a '
        : ' is NOT a ';

      const footerMessageSuffix = ' Balance.';

      const footerMessage = <p>{footerMessagePrefix}<b>{item.name}</b>{footerMessageMiddle}<b>{container.name}</b>{footerMessageSuffix}</p>;

      if(result.is_correct) {
        answerCorrectEffect.current.play().catch(e => console.log('Error playing answer correct sound:', e));

        setShouldScorePlaySound(true);
        showFooterMessage('success', footerMessage);
        setTotalScore(prevTotalScore => prevTotalScore + result.score_points);
        setItemsToBeSorted(prevItems => {
          const updatedItems = prevItems.filter(prevItem => prevItem.id !== item.id);
          if(!updatedItems.length) {
            setIsPlaying(false);
            setModalToShow('well-done');
          }
          return updatedItems;
        });
      } else {
        answerWrongEffect.current.play().catch(e => console.log('Error playing answer wrong sound:', e));

        showFooterMessage('error', footerMessage);
      }
    };
  
    decisionMutation.mutate({
      user: user?.id,
      game: currentGameId,
      question: item.id,
      selected_option: container.id,
      time_elapsed: time
    }, {
      onSuccess: onSuccess,
      onError: (error) => {
        console.log('Error: ', error);
      }
    });
  };

  return (
    <div className="game-level-wrapper">
      {
        showIntro && (
          <Intro
            title={`Level ${levelNumber}`}
            description={scenarioLevel.description}
            onShowingCompleted={handleIntroClose}
          />
      )}
      <Header 
        time={time}
        totalScore={totalScore}
        shouldScorePlaySound={shouldScorePlaySound}
      />
      <section className="main-section main-section-sorting" style={{backgroundImage: `url(${Background})`}}>
        <div className="container columns">
          <Column
            side='left'
            options={options[0]}
            levelNumber={levelNumber}
            prePlacedItems={placedItems}
            gameType="Normal Balance"
            isGameSorted={true}
            onSortedItemClick={handleSortedItemClick}
          />
          <main className="content">
          </main>
          <Column
            side='right'
            options={options[1]}
            levelNumber={levelNumber}
            prePlacedItems={placedItems}
            gameType="Normal Balance"
            isGameSorted={true}
            onSortedItemClick={handleSortedItemClick}
          />
        </div>
      </section>
      <footer className="game-footer">
        {isFooterMessageShown && (
          <FooterMessage
            type={footerMessageType}
            description={footerMessageDescription}
          />
        )}
      </footer>
      {modalToShow && (
        <Modal
          content={modalContent}
          isModalOverlayVisible={isModalOverlayVisible}
          isModalSmall={isModalSmall}
        />
      )}
    </div>
  );
}
