export const blockItems = [
  [
    {
      text: 'Assets',
      answer: 'Balance Sheet',
      description: <p>An <b>Asset</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Expenses',
      answer: 'Income Statement',
      description: <p>An <b>Expenses</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Equity',
      answer: 'Balance Sheet',
      description: <p>An <b>Equity</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Revenues',
      answer: 'Income Statement',
      description: <p>A <b>Revenues</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Liabilities',
      answer: 'Balance Sheet',
      description: <p>A <b>Liabilities</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }
  ],
  [
    {
      text: 'Current Assets',
      answer: 'Assets',
      description: <p>A <b>Current Assets</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Long Term Assets',
      answer: 'Assets',
      description: <p>A <b>Long Term Assets</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Current Liabilities',
      answer: 'Liabilities',
      description: <p>A <b>Current Liabilities</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Long Term Liabilities',
      answer: 'Liabilities',
      description: <p>A <b>Long Term Liabilities</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Contributed Capital',
      answer: 'Equity',
      description: <p>A <b>Contributed Capital</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Earned Capital',
      answer: 'Equity',
      description: <p>An <b>Earned Capital</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Revenues',
      answer: 'Revenues',
      description: <p>A <b>Revenues</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Direct Expenses',
      answer: 'Expenses',
      description: <p>A <b>Direct Expenses</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Indirect Expenses',
      answer: 'Expenses',
      description: <p>An <b>Indirect Expenses</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }
  ],
  [
    {
      text: 'Cash',
      answer: 'Current Assets',
      description: <p><b>Cash</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Accounts Receivable',
      answer: 'Current Assets',
      description: <p><b>Accounts Receivable</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }, 
    {
      text: 'Inventory',
      answer: 'Current Assets',
      description: <p><b>Inventory</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Property, Plant, and Equipment',
      answer: 'Long-term Assets',
      description: <p><b>Property, Plant, and Equipment</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Accounts Payable',
      answer: 'Current Liabilities',
      description: <p><b>Accounts Payable</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Wages & Salaries Payable',
      answer: 'Current Liabilities',
      description: <p><b>Wages & Salaries Payable</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Bonds Payable',
      answer: 'Long-term Liabilities',
      description: <p><b>Bonds Payable</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Stock Issuance',
      answer: 'Contributed Capital',
      description: <p><b>Stock Issuance</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Retained Earnings',
      answer: 'Earned Capital',
      description: <p><b>Retained Earnings</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Net Revenues',
      answer: 'Revenues',
      description: <p><b>Net Revenues</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Cost of Goods Sold',
      answer: 'Direct Expenses',
      description: <p><b>Cost of Goods Sold</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Research & development (R&D)',
      answer: 'Indirect Expenses',
      description: <p><b>Research & development (R&D)</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Marketing',
      answer: 'Indirect Expenses',
      description: <p><b>Marketing</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'General & Administrative (G&A)',
      answer: 'Indirect Expenses',
      description: <p><b>General & Administrative (G&A)</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    },
    {
      text: 'Interest Expenses',
      answer: 'Indirect Expenses',
      description: <p><b>Interest Expenses</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</p>,
      secondsFalling: -1,
      accuracyCoefficient: 1
    }
  ]
];


export const leftColumnAnswers = [
  [
    {
      title: 'Balance Sheet'
    }
  ],
  [
    {
      title: 'Assets'
    },
    {
      title: 'Liabilities'
    },
    {
      title: 'Equity'
    }
  ],
  [
    {
      title: 'Current Assets',
      parentTitle: 'Assets'
    },
    {
      title: 'Long-term Assets',
      parentTitle: 'Assets',
      groupTitle: true
    },
    {
      title: 'Current Liabilities',
      parentTitle: 'Liabilities'
    },
    {
      title: 'Long-term Liabilities',
      parentTitle: 'Liabilities',
      groupTitle: true
    },
    {
      title: 'Contributed Capital',
      parentTitle: 'Equity'
    },
    {
      title: 'Earned Capital',
      parentTitle: 'Equity',
      groupTitle: true
    },
  ]
];

export const rightColumnAnswers = [
  [
    {
      title: 'Income Statement'
    }
  ],
  [
    {
      title: 'Revenues'
    },
    {
      title: 'Expenses'
    }
  ],
  [
    {
      title: 'Revenues',
      groupTitle: true
    },
    {
      title: 'Direct Expenses',
      parentTitle: 'Expenses'
    },
    {
      title: 'Indirect Expenses',
      parentTitle: 'Expenses',
      groupTitle: true
    }
  ]
];


export const buttonTitles = ['The Elements of the Financial Statements', 'Financial Statements Sub-Categories', 'Financial Statements Accounts'];
export const modalDescriptions = [
  'Sort the Elements to the correct Financial Statements',
  'Sort each sub-categories under the correct element of the financial statements',
  'Sort each account under the correct sub-categories of the financial statements',
];

export const popupMessagesGameLevel1 = [
  {
    name: 'Bailey',
    text: 'Today, we\'re doing a training exercise called <b>The Normal Balance game.</b>',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'This exercise will enhance your understanding of <b><a href="https://www.accountingcoach.com/debits-and-credits/explanation/3" target="_blank" rel="noreferrer">the Normal Balance concept</a></b> in accounting, where every account on financial statements must have a normal balance categorized as either a debit or a credit.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Your task is to sort each falling account to its correct normal balance. <br>You’ll earn points and badges based on how quickly you’re able to correctly sort the accounts to their normal balances.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'The exercise starts easy and gradually ramps up in difficulty to Level 5. <br>That said, let\'s begin with Level I: Sorting <b><a href="https://www.accountingtools.com/articles/what-are-the-elements-of-financial-statements.html" target="_blank" rel="noreferrer">the 5 Elements.</a></b>',
    alignment: 'right'
  },
];

export const popupMessagesGameLevel2 = [
  {
    name: 'Bailey',
    text: 'Congratulations on finishing Level I! Knowing the normal balance of <b><a href="https://www.accountingtools.com/articles/what-are-the-elements-of-financial-statements.html" target="_blank" rel="noreferrer">the 5 elements</a></b> will make it easier for you to know the normal balances of all other accounts. ',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'In this next level, we’re going to present several commonly used financial statement accounts.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Your task is to sort these accounts to their respective normal balance: either debit or credit.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> Usually, an account\'s normal balance matches the normal balance of the element it\'s linked to. For example, if the account falls under the asset element, the account’s normal balance is typically a debit. <br>All the best!',
    alignment: 'right'
  },
];

export const popupMessagesGameLevel3 = [
  {
    name: 'Bailey',
    text: 'Congrats on completing Level 2! You’ve now sorted several commonly used accounts to their correct normal balance.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'In this next level, you\'ll encounter accrual and deferral accounts, which can be tricky to sort due to their names.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Your task is to sort these accrual and deferral accounts to their correct normal balance.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> Be careful when using the account name to determine its normal balance. Some accounts may have names like "expense" but belong to a different element category, like assets. Always check the account definition when you’re unsure. <br><br>Sending you positive vibes!',
    alignment: 'right'
  },
];

export const popupMessagesGameLevel4 = [
  {
    name: 'Bailey',
    text: 'Congrats on completing Level 3! You’ve now sorted the typical accounts.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'In this level, you\'ll work with <b><a href="https://www.superfastcpa.com/what-are-contra-accounts/" target="_blank" rel="noreferrer">contra-accounts</a></b>, which are accounts that reduce the value of related accounts. For instance, if a business buys an asset for $100 and its value decreases by $20, a $20 contra-asset account might be created to adjust the asset value down to $80.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'Your task is to sort these contra accounts to their correct normal balance.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> These accounts have a normal balance that is opposite to their associated elements. For instance, while a typical asset account has a debit balance, a contra-asset account will have a credit balance instead. <br><br>Best of luck!',
    alignment: 'right'
  },
];

export const popupMessagesGameLevel5 = [
  {
    name: 'Bailey',
    text: 'Congrats on completing Level 4! You’ve now made it to the final level.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: 'At this level, you’ll encounter all the different types of accounts including the elements, accruals, deferrals, and contra-accounts.',
    alignment: 'right'
  },
  {
    name: 'Bailey',
    text: 'These accounts are scrambled and your task is to sort these scrambled accounts to their correct normal balance.',
    alignment: 'left'
  },
  {
    name: 'Benny',
    text: '<b class="red">Tip:</b> Here\'s a mnemonic to aid in recalling the normal balance rule: <ul><li>Assets and expenses accounts: Debit is normal.</li><li>Other accounts: Credit is normal.</li><li>Exception: Contra-accounts act in the opposite manner.</li></ul>',
    alignment: 'right'
  },
];

export const popupMessagesAccuracy = {
  Master: [
    {
      name: 'Benny',
      text: 'Congratulations {name}! You sorted the {items} with {accuracy} accuracy in an average of 2 seconds or less.'
    },
    {
      name: 'Benny',
      text: 'You’re performing at a mastery level. Great job!'
    },
    {
      name: 'Benny',
      text: '{generalTexts}'
    },
  ],
  Warrior: [
    {
      name: 'Benny',
      text: 'Impressive {name}! You sorted the {items} with {accuracy} accuracy in an average of {avarageTime} seconds.'
    },
    {
      name: 'Benny',
      text: 'To demonstrate mastery, you must sort all items with 100% accuracy in 2 seconds or less.'
    },
  ],
  Newbie: [
    {
      name: 'Benny',
      text: 'Not bad {name}! You sorted the {items} with {accuracy} accuracy in an average of {avarageTime} seconds.'
    },
    {
      name: 'Benny',
      text: 'To demonstrate mastery, you must sort all items with 100% accuracy in 2 seconds or less.'
    },
  ],
  Noob: [
    {
      name: 'Benny',
      text: 'You sorted the {items} with {accuracy} accuracy in an average of {avarageTime} seconds.'
    },
    {
      name: 'Benny',
      text: 'To demonstrate mastery, you must sort all items with 100% accuracy in 2 seconds or less.'
    },
  ]
};

export const popupMessagesAccuracyItems = [
  'Elements',
  'accounts',
  'accrual &amp; deferral accounts',
  'contra-asset accounts',
  'various accounts'
];

export const popupMessagesAccuracyGeneralTexts = [
  'Did you know that all financial statement accounts fall under one of these five elements: assets, liabilities, equity, revenue, or expenses? Therefore understanding the normal balances of these elements will help you master the normal balances for all financial statement accounts.',
  'These are common accounts that you’ll typically find on a company’s financial statement. Understanding which elements these accounts belong to helps in comprehending how they impact financial statements.',
  'The normal balance for accrual and deferral accounts can be confusing due to their names. For example, accrued expenses are liabilities. These are costs that a company incurs but they haven’t paid yet.',
  'The normal balance for contra-accounts can be confusing because they function oppositely to their primary accounts. For example, while assets typically have a debit balance, a contra-asset account like accumulated depreciation has a credit balance.',
  'A key takeaway from this exercise is understanding the normal balances of financial statement accounts by identifying the element they belong to (e.g., assets, liabilities, equity, revenue, or expenses). Mastering these normal balances provides a solid foundation for more advanced accounting concepts and practices.'
];

export const popupMessagesMostChallenging = [
  {
    name: 'Benny',
    text: 'These were the most challenging {items} for you based on your accuracy and speed.'
  },
  {
    name: 'Benny',
    text: '{generalTexts}'
  },
];

export const tutorialBlockItems = [{
  name: 'Assets',
  answers: ['Balance Sheet', 'Debit'],
  description: <span>An <b>Asset</b> is something of value that a person or a company owns. It can be converted into money or provides future benefits.</span>,
  secondsFalling: -1,
  accuracyCoefficient: 1
}];

export const tutorialOptions = [
  {
    "id": 1,
    "order": 1,
    "name": "Debit",
    "description": "A balance sheet is a financial statement that provides a snapshot of a company's financial position at a specific point in time. It is one of the key financial statements used by businesses, investors, and analysts to assess the financial health and performance of an organization.",
    "children": [],
    "itemId": 1
  },
  {
    "id": 2,
    "order": 2,
    "name": "Credit",
    "description": "An income statement, also known as a profit and loss statement (P&L) is a financial statement that summarizes a company's revenues and expenses over a specific period of time.",
    "children": [],
    "itemId": 2
  }
];