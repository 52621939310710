import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import useAuth from '../../auth/useAuth';
import { gamesQuery, getGamesSessions } from './api';

import Layout from '../UI/introGameLayout/layout';

import BackgroundImageLevel1 from './images/level1-background.jpg';
import BackgroundImageOtherLevels from './images/other-levels-background.jpg';

import IntroProcessSound from './sounds/intro-process.mp3';

import {
  popupMessagesGameLevel1,
  popupMessagesGameLevel2,
  popupMessagesGameLevel3,
  popupMessagesGameLevel4,
  popupMessagesGameLevel5
} from './data';

import BackgroundSound from '../UI/introGameLayout/backgroundSound';
import PopupMessages from '../UI/introGameLayout/popupMessages';

import './styles.css';

export default function NormalBalanceIntro() {
  const { getUser } = useAuth();
  const user = getUser();

  const [currentGameId, setCurrentGameId] = useState(0);

  const { data: games } = useQuery(gamesQuery()) || {};

  useEffect(() => {
    if(games) {
      const game = games.find(game => game.game_type.includes('Normal Balance'));

      if(game && game.scenario) {
        setCurrentGameId(game.id);
      }
    }
  }, [games]);

  const navigate = useNavigate();

  const params = useParams();
  const levelNumber = Number(params.levelNumber);

  const BackgroundImage = levelNumber === 1
    ? BackgroundImageLevel1
    : BackgroundImageOtherLevels;

  const isBackgroundBlurred = levelNumber === 1
    ? true
    : false;

  const popupMessages = {
    1: popupMessagesGameLevel1,
    2: popupMessagesGameLevel2,
    3: popupMessagesGameLevel3,
    4: popupMessagesGameLevel4,
    5: popupMessagesGameLevel5,
  };

  const popupMessagesGameIntro = popupMessages[levelNumber] || popupMessagesGameLevel1;

  const buttonText = levelNumber === 1
    ? 'Let\'s go'
    : levelNumber < 5
      ? 'Start Level ' + levelNumber
      : 'Start Mastery Level';

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessions'],
    queryFn: getGamesSessions,
  });
  
  const handleBottomMessageButtonClick = () => {
    const tutorialCompleted = gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId);
    if(levelNumber === 1 && !tutorialCompleted) {
      navigate(`/normal/tutorial`);
    } else {
      navigate(`/normal/level/${levelNumber}`);
    }
  }

  return(
    <Layout
      isHeaderVisible={true}
      backgroundImage={BackgroundImage}
      isBackgroundBlurred={isBackgroundBlurred}
    >
      <BackgroundSound 
        sound={IntroProcessSound}
      />
      <PopupMessages
        messages={popupMessagesGameIntro}
        buttonText={buttonText}
        onButtonClick={handleBottomMessageButtonClick}
        shouldType={false}
      />
    </Layout>
  );
}
