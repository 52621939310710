import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Layout from '../UI/introGameLayout/layout';

import {
  gamesQuery,
  getGamesSessions,
  scenarioLevelsQuery,
  getDecisions,
  getTransactionDecisions,
  getNormalDecisions,
  getEquationDecisions
} from './api';
import useAuth from '../../auth/useAuth';

import { getHighestActiveGame } from '../../utils';

import './styles.css';

import StudentImage from './images/transition-student.png';
import InstructorImage from './images/transition-instructor.jpg';
import NormalImage from './images/transition-normal.png';
import TheProfitImage from './images/transition-the-profit.png';
import BalancedEquationImage from './images/transition-balanced-equation.png';

export default function Transition() {
  const navigate = useNavigate();
  const { 
    getUser,
    isInstructor,
    isStudent,
    getCurrentGameId,
    getGuidedPlay
  } = useAuth();
  const user = getUser();

  const shouldGuidedPagesBeDisplayed = getGuidedPlay();
  
  const [transitionImage, setTransitionImage] = useState();
  const [imageClass, setImageClass] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageSubtitle, setPageSubtitle] = useState('');
  const [textUnderTheImage, setTextUnderTheImage] = useState('');

  const selectedGameId = getCurrentGameId();
  const [currentGameId, setCurrentGameId] = useState(selectedGameId);
  const [scenarioId, setScenarioId] = useState(0);
  const [gameType, setGameType] = useState('');

  const { data: games } = useQuery(gamesQuery()) || {};
  useEffect(() => {
    if(games) {
      const game = currentGameId
        ? games.find(game => game.id === currentGameId)
        : getHighestActiveGame(games);

      if(game && game.scenario) {
        setCurrentGameId(game.id);
        setScenarioId(game.scenario);
        setGameType(game.game_type)
      }
    }
  }, [games, currentGameId]);

  const { data: gamesSessions } = useQuery({
    queryKey: ['gamesSessions'],
    queryFn: getGamesSessions,
  });

  const scenarioLevelsResponse = useQuery(scenarioLevelsQuery(scenarioId)) || {};

  const { data: decisionsResponse } = useQuery({
    queryKey: ['decisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && !!currentGameId,
  });

  const { data: transactionDecisionsResponse } = useQuery({
    queryKey: ['transactionDecisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getTransactionDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && !!currentGameId,
  });
  
  const { data: equationDecisionsResponse } = useQuery({
    queryKey: ['equationDecisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getEquationDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && !!currentGameId,
  });
  
  const { data: normalDecisionsResponse } = useQuery({
    queryKey: ['normalDecisionsResponse', { userId: user?.id, gameId: currentGameId }],
    queryFn: () => getNormalDecisions({ userId: user?.id, gameId: currentGameId }),
    enabled: !!user?.id && !!currentGameId,
  });

  useEffect(() => {
    const userIsInstructor = isInstructor();
    const userIsStudent = isStudent();

    if (user && gameType) {
      if(gameType.includes('Account Classification')) {
        if (userIsInstructor) {
          setTransitionImage(InstructorImage);
          setImageClass('transition-image-instructor');
          setPageTitle('Welcome To The Account Classification Game!');
          setPageSubtitle(`Hi${user?.first_name ? ' ' + user?.first_name : ''}!`);
        } else if (userIsStudent) {
          setTransitionImage(StudentImage);
          setImageClass('transition-image-student');
          setPageTitle('Account Classification Game');
          setPageSubtitle(`Hello${user?.alias_name ? ' ' + user?.alias_name : ''}! You are invited to play the`);
        }
      } else if (gameType.includes('Normal Balance')) {
        setTransitionImage(NormalImage);
        setImageClass('transition-image-student');
        setPageTitle('Welcome To The Normal Balance Game!');
        setPageSubtitle(`Hi${user?.alias_name ? ' ' + user?.alias_name : ''}!`);
      } else if(gameType.includes('The Profit')) {
        setTransitionImage(TheProfitImage);
        setPageTitle('The Profit Game');
      } else if(gameType.includes('Balanced Equation')) {
        setTransitionImage(BalancedEquationImage);
        setPageTitle('The Balanced Equation');
        setTextUnderTheImage('As in life, so too in accounting: balance is key');
      }
    }
  }, [
    navigate,
    getUser,
    isInstructor,
    isStudent,
    games,
    currentGameId,
    user,
    gameType
  ]);

  useEffect(() => {
    let redirectUrl = '';
    const scenarioLevels = scenarioLevelsResponse.data || [];
    const sortedScenarioLevels = scenarioLevels.filter(scenarioLevel => scenarioLevel.level !== null).sort((a, b) => a.level - b.level);

    if(gameType.includes('The Profit')) {
      redirectUrl = '/the-profit/tutorial';
      if (gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) { 
        const transactionDecisions = transactionDecisionsResponse || [];
        
        const hasNotCompletedLevels = sortedScenarioLevels.some((scenarioLevel) => {
          const unansweredTransatctions = scenarioLevel.transactions.find((transaction) => {
            const correctDecision = transactionDecisions.find((transactionDecision) => transactionDecision.question === transaction.id && transactionDecision.is_correct);
            return !correctDecision;
          });
          
          if (unansweredTransatctions) {
            redirectUrl = `/the-profit/level/${scenarioLevel.level}`;
            return true;
          }
          
          return false;
        });
  
        if (!hasNotCompletedLevels) {
          redirectUrl = '/the-profit/debrief';
        }
      } else {
        redirectUrl = '/the-profit/tutorial'
      }
    } else if (gameType.includes('Account Classification')) {
      if(gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
        const decisions = decisionsResponse || [];
        const hasNotCompletedLevels = sortedScenarioLevels.some((scenarioLevel) => {
          const unansweredQuestion = scenarioLevel.questions.find((question) => {
            const correctDecision = decisions.find((decision) => decision.question === question.id && decision.is_correct);
            return !correctDecision;
          });
          
          if (unansweredQuestion) {
            const levelDecisionsMade = decisions.some(decision => decision.level === scenarioLevel.level);

            if(levelDecisionsMade) {
              redirectUrl = `/classification/level/${scenarioLevel.level}`;
            } else {
              if(shouldGuidedPagesBeDisplayed) {
                redirectUrl = `/classification/level/${scenarioLevel.level}/intro`;
              } else {
                redirectUrl = `/classification/level/${scenarioLevel.level}`;
              }
            }
            return true;
          }
          
          return false;
        });
  
        if (!hasNotCompletedLevels) {
          redirectUrl = '/classification/feedback';
        }
      } else {
        if(shouldGuidedPagesBeDisplayed) {
          redirectUrl = '/classification/level/1/intro';
        } else {
          redirectUrl = `/classification/tutorial`;
        }
      }
    } else if (gameType.includes('Normal Balance')) {
      if(gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) { 
        const decisions = normalDecisionsResponse || [];
        
        const hasNotCompletedLevels = sortedScenarioLevels.some((scenarioLevel) => {
          const unansweredQuestion = scenarioLevel.normal_questions.find((question) => {
            const correctDecision = decisions.find((decision) => decision.question === question.id && decision.is_correct);
            return !correctDecision;
          });

          if (unansweredQuestion) {
            const levelDecisionsMade = decisions.some(decision => decision.level === scenarioLevel.level);
            if(levelDecisionsMade) {
              redirectUrl = `/normal/level/${scenarioLevel.level}`;
            } else {
              if(shouldGuidedPagesBeDisplayed) {
                redirectUrl = `/normal/level/${scenarioLevel.level}/intro`;
              } else {
                redirectUrl = `/normal/level/${scenarioLevel.level}`;
              }
            }
            return true;
          }
          
          return false;
        });
  
        if (!hasNotCompletedLevels) {
          redirectUrl = `/normal/level/${sortedScenarioLevels.length}/accuracy`;
        }
      } else {
        if(shouldGuidedPagesBeDisplayed) {
          redirectUrl = '/normal/level/1/intro';
        } else {
          redirectUrl = '/normal/tutorial';
        }
      }
    } else if (gameType.includes('Balanced Equation')) {
      if(gamesSessions && gamesSessions.some(session => session.user === user?.id && session.is_ready === true && session.game === currentGameId)) {
        const decisions = equationDecisionsResponse || [];
        if(decisions.length) {
          const hasNotCompletedLevels = sortedScenarioLevels.some((scenarioLevel) => {
            const unansweredQuestion = scenarioLevel.be_questions.find((question) => {
              const correctDecision = decisions.find((decision) => decision.question === question.id && decision.question_answered_correctly);
              return !correctDecision;
            });
            
            if (unansweredQuestion) {
              const levelDecisionsMade = decisions.some(decision => decision.level === scenarioLevel.level);
              if(levelDecisionsMade) {
                redirectUrl = `/balanced-equation/level/${scenarioLevel.level}`;
              } else {
                if(shouldGuidedPagesBeDisplayed) {
                  redirectUrl = `/balanced-equation/level/${scenarioLevel.level}/intro`;
                } else {
                  redirectUrl = `/balanced-equation/level/${scenarioLevel.level}`;
                }
              }
              return true;
            }
            
            return false;
          });
    
          if (!hasNotCompletedLevels) {
            redirectUrl = `/balanced-equation/level/${sortedScenarioLevels.length}/`;
          }
        } else {
          if(shouldGuidedPagesBeDisplayed) {
            redirectUrl = `/balanced-equation/level/1/intro`;
          } else {
            redirectUrl = '/balanced-equation/level/1/';
          }
        }
      } else {
        if(shouldGuidedPagesBeDisplayed) {
          redirectUrl = `/balanced-equation/level/1/intro`;
        } else {
          redirectUrl = '/balanced-equation/level/1/tutorial';
        }
      }
    }

    const timer = setTimeout(() => {
      navigate(redirectUrl);
    }, 4500);

    return () => clearTimeout(timer);
  }, [
    gameType,
    scenarioLevelsResponse.data,
    currentGameId,
    gamesSessions,
    decisionsResponse,
    transactionDecisionsResponse,
    normalDecisionsResponse,
    equationDecisionsResponse,
    user?.id,
    navigate
  ])

  return (
    <Layout isHeaderVisible={true}>
      <div className="page-content transition-page-content">
        <h2 className="page-subtitle">{pageSubtitle}</h2>
        <h1 className="page-title">{pageTitle}</h1>
        <div className="loader"/>
        {
          transitionImage &&
            <img src={transitionImage} alt="Welcome" className={`transition-image ${imageClass}`} />
        }
        {
          textUnderTheImage &&
            <p className="transition-under-the-image">{textUnderTheImage}</p>
        }
      </div>
    </Layout>
  );
}
