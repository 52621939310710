import { useRef, useEffect } from 'react';

import { useSoundStatus } from '../../../../context/SoundStatusContext';

export default function BackgroundSound({ sound }) {
  const { soundStatus } = useSoundStatus();
  const backgroundEffect = useRef(new Audio(sound));

  useEffect(() => {
    console.log()
    if (soundStatus === 'enabled') {
      backgroundEffect.current.volume = 0.045;
      backgroundEffect.current.loop = true;
      backgroundEffect.current.play().catch(e => console.log('Error playing game process sound:', e));
    } else {
      backgroundEffect.current.pause();
    }

    return () => {
      backgroundEffect.current.pause();
    };
  }, [soundStatus]); 

  return null;
}
