import { useState } from 'react';

import './styles.css';

export default function InstructorTransactions({
  transactions,
  selectedTransactionId,
  onSelectChange
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSelectToggle = () => {
    setIsExpanded(!isExpanded);
  };
  
  const handleSelectChange = (selectTransaction) => {
    onSelectChange(selectTransaction);
    setIsExpanded(false);
  };
  
  return (
    <div className="instructor-transactions-container">
      <h3>THE TRANSACTIONS</h3>
      <div className="transactions-select-wrapper">
        <div className="transactions-select-header" onClick={handleSelectToggle}>
          <span className="text-wrapper">
            <span>{selectedTransactionId ? transactions.find(i => i.id === selectedTransactionId)?.description : transactions[0]?.description}</span>
          </span>
          <span className="transactions-select-icon"></span>
        </div>
        <div className={`transactions-select-body ${isExpanded ? 'active' : ''}`}>
          <ul>
            {transactions.map((transaction, index) => (
              <li 
                key={index}
                onClick={() => handleSelectChange(transaction)}
              >
                {transaction.description}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
